import React from "react";
import { Link, navigate } from "gatsby";

import { 
  Seo, 
  Zoom,
 } from "@components";
 import { PageLayout } from "../../../components/site/layout/page-layout";
 import { 
  RightSidebar,
  BackendRuntimeSecretsImage, 
  RuntimeSecretsProtectionBanner 
} from "../../../components/site/product";

import { BenefitCard } from "../../../components/core/benefit-card";
import { HardcodedSecretIcon } from '../../../components/core/icons/hardcoded_secret'
import { FlexibilityIcon } from '../../../components/core/icons/flexibility'
import { NoChangeIcon } from '../../../components/core/icons/no_change'

const ProductRuntimeSecrets = ({ location }) => {
  return (
    <PageLayout pathname={location.pathname}>
      <RuntimeSecretsProtectionBanner title="Runtime Secrets Protection"/>

      <div className="container pt-[6rem] pb-[70px] text-[#262727]">
        <div className="w-full flex flex-col lg:flex-row px-[32px] gap-[38px]">
          {/* Left Side */}
          <div className="w-full">
              <div className="flex flex-col px-[20px] md:px-[28px] py-[30px] md:py-[40px] items-start gap-[15px] rounded-[10px] bg-[#F2F5F9]">
                  <h2 className="text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">
                    Runtime Secrets
                  </h2>
                  <div className="w-full xs:w-[230px] h-[4px] bg-[#B21C7A]"></div>
                  <p className="text-[17px] leading-[29px] m-0">
                    Runtime Secrets is an innovative technology that allows API keys and other secrets to be completely removed from the app package shipped to the app store. Instead, secrets are securely delivered to valid app instances at runtime, improving the security posture and providing significantly enhanced operational flexibility.
                  </p>
              </div>

              <h2 className="mt-[50px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Benefits</h2>
              <BenefitCard 
                className="mt-[20px]"
                Icon={
                  <HardcodedSecretIcon className="w-[55px] h-[55px] flex-shrink-0" />
                }
                title="Eliminate Secrets"
                text="Migrate your dangerous hardcoded secrets from app packages into the cloud where they can be securely managed"
              />
              <div className="mt-[25px] w-full flex flex-col md:flex-row gap-[18px]">
                <BenefitCard 
                  className="md:w-1/2"
                  Icon={
                    <FlexibilityIcon className="w-[55px] h-[55px] flex-shrink-0" />
                  }
                  title="Operational Flexibility"
                  text="Improved operational flexibility by rotating secrets on demand"
                />
                <BenefitCard
                  className="md:w-1/2"
                  Icon={
                    <NoChangeIcon className="w-[55px] h-[55px] flex-shrink-0" />
                  }
                  title="Implement with No Changes"
                  text="Implement with no changes needed to backend APIs"
                />
              </div>
              <div className="w-full flex justify-center mt-[40px]">
                <button className="text-[16px] text-[#fff] font-medium leading-[28px] bg-[#78A65A] px-[38px] py-[16px] rounded-[7px]"
                  onClick={() => {
                    navigate('https://approov.io/info/schedule-a-demo')
                  }}
                >
                  Request a Demo
                </button>
              </div>

              <h2 className="mt-[80px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Just-in-Time Secrets From The Cloud</h2>
              <p className="mt-[20px] mb-[30px] text-[17px] leading-[29px]">
                Approov Runtime Secrets Protection can be used to protect and manage all the secrets a mobile app uses, including API keys used to authenticate against various backend services. With Approov you no longer need to hardcode these in your app, where they were both fixed and subject to reverse engineering extraction.
              </p>
              <p className="mt-[20px] mb-[30px] text-[17px] leading-[29px]">
                The Approov cloud service delivers secrets “just-in-time” to the app only at the moment they are required to make an API call, and only when the app and its runtime environment has passed attestation. This ensures that sensitive secrets cannot be extracted from the app package or via <Link href="https://approov.io/info/how-to-prevent-mitm-attacks-btw-mobile-apps-and-apis" className="">Man-in-the-Middle (MitM) attacks.</Link>
              </p>

              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] mb-[20px]">Secure Secrets Delivery</h2>
              <Zoom>{BackendRuntimeSecretsImage}</Zoom>

              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Dynamically Managed</h2>
              <p className="mt-[20px] mb-[0px] text-[17px] leading-[29px]">
                Shift left with Approov and integrated runtime secrets management, giving you complete operational flexibility and observability. Rotate secrets as needed and eliminate the risk of secrets exposure damaging your business.
              </p>
              <p className="mt-[20px] mb-[0px] text-[17px] leading-[29px]">
                All secrets are stored by the Approov cloud service and are easy to manage dynamically. Certificates, pins, and API keys can easily and immediately be updated across all deployed apps. In this way, if secrets are ever stolen from cloud repositories or acquired through other means, or if a third-party API used by your app changes keys, they can immediately be rotated without any service interruption and without having to update apps.
              </p>

              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Secured Remote Configuration</h2>
              <p className="mt-[20px] mb-[0px] text-[17px] leading-[29px]">
                Some approaches exist whereby an app can receive secrets and other configuration from a remote server. This removes the secrets from the app and allows dynamic updates of the configuration but it isn't secure. An attacker can send a request to the endpoint to get the secrets. Moreover, the secrets are not safe once they reach the mobile app without tamper and communication channel protections.
              </p>
              <p className="mt-[20px] mb-[0px] text-[17px] leading-[29px]">
                Approov provides a unique combination of remote configuration capability along with advanced app protections. This ensures that secrets can only ever be delivered to authentic apps that have proved they are not being manipulated.
              </p>

              {/* <div className="mt-[70px] flex justify-center px-[90px] py-[40px] items-start gap-[15px] rounded-[10px] bg-[#F2F5F9]">
                  <h2 className="text-center text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">
                    Recommended Resources About Approov Mobile RASP Security
                  </h2>
              </div> */}
          </div>
          {/* Right Side */}
          <RightSidebar location={location.pathname} />
        </div>
      </div>
    </PageLayout>
  );
};

export default ProductRuntimeSecrets;
